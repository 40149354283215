import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import BaseModal from 'components/Modals/BaseModal/BaseModal';
import Button from 'components/Button';
import BACKEND_URL from 'constants/backendUrl';
import { POST } from 'utils/http';
import { RadioImageField, TextInput } from '../lib';
import EventMood from 'core/enums/eventMood';
import { CloseModal, OpenModal } from 'core/useCases/modal/modalAction';
import { DisabledField, ModalType } from 'core/useCases/modal/types';
import { UpdateContact } from 'core/useCases/contacts/contactActions';
import { UpdateEvent } from 'core/useCases/events/eventActions';
import Event from 'core/models/event';
import { AddTask, UpdateTask } from 'core/useCases/tasks/taskActions';
import Task from 'core/models/task';
import Mood1 from 'images/icons/Mood1.svg';
import Mood2 from 'images/icons/Mood2.svg';
import Mood3 from 'images/icons/Mood3.svg';
import Mood4 from 'images/icons/Mood4.svg';
import ActivityType from 'core/enums/activityType';
import { UpdateApplication } from 'core/useCases/applications/applicationActions';
import ResourcesInput from '../lib/ResourcesInput';

type Inputs = {
    comment: string;
    mood?: EventMood;
    resources: string;
};

type AddActivityReportResponse = {
    activity: Event | Task;
    automaticTasks: Task[];
};

const addActivityReportRequest = async (
    inputs: Inputs,
    id: string,
    activityType: ActivityType,
): Promise<AddActivityReportResponse> => {
    const response = (await POST<AddActivityReportResponse>(`${BACKEND_URL}/api/activity/add-report`, {
        ...inputs,
        activityType,
        id,
    })) as AddActivityReportResponse;

    return response;
};

type UpdateActivityReportResponse = {
    activity: Event | Task;
};

const updateActivityReportRequest = async (
    inputs: Inputs,
    id: string,
    activityType: ActivityType,
): Promise<UpdateActivityReportResponse> => {
    const response = (await POST<UpdateActivityReportResponse>(`${BACKEND_URL}/api/activity/update-report`, {
        ...inputs,
        activityType,
        id,
    })) as UpdateActivityReportResponse;

    return response;
};

type ActivityReportFormProps = {
    closeModal(): void;
    data: {
        id: string;
        activityType: ActivityType;
        isAlreadyCompleted: boolean;
        comment: string;
        mood?: EventMood;
    };
};

const ActivityReportForm: FC<ActivityReportFormProps> = ({ closeModal, data }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { id, activityType, isAlreadyCompleted, comment, mood } = data;

    const methods = useForm<Inputs>({
        defaultValues: { comment, mood },
    });
    const { handleSubmit } = methods;

    const openNewTaskModal = (activity: Event | Task) => {
        const { contact, application } = activity;
        const disabledFields: Array<DisabledField> = [];
        if (application) {
            disabledFields.push(DisabledField.ApplicationId);
        } else if (contact) {
            disabledFields.push(DisabledField.ContactId);
        }
        dispatch(
            OpenModal({
                cancelButtonLabel: t('common.ignore'),
                defaultValues: {
                    application,
                    contact,
                },
                disabledFields,
                title: t('task.new-task-modal-title'),
                type: ModalType.Task,
            }),
        );
    };

    const updateActivityResourcesRequest = async (
        inputs: Inputs,
        ActivityId: string,
        activityTypes: ActivityType,
    ): Promise<void> => {
        (await POST(`${BACKEND_URL}/api/activity/update-additional-information`, {
            activityType: activityTypes,
            id: ActivityId,
            inputs,
        })) as Event | Task;
    };

    const onSubmit = handleSubmit(async (values: Inputs) => {
        try {
            if (values.resources.length > 2) {
                await updateActivityResourcesRequest(values, id, activityType);
            }
            if (isAlreadyCompleted) {
                const { activity } = await updateActivityReportRequest(values, id, activityType);
                if (ActivityType.Task === activityType) {
                    dispatch(UpdateTask(activity as Task));
                } else {
                    dispatch(UpdateEvent(activity as Event));
                }
                dispatch(CloseModal());
            } else {
                const { activity, automaticTasks } = await addActivityReportRequest(values, id, activityType);
                if (ActivityType.Task === activityType) {
                    dispatch(UpdateTask(activity as Task));
                } else {
                    dispatch(UpdateEvent(activity as Event));
                }
                // The updating of the activity can update the linked contact too
                if (activity?.contact) {
                    dispatch(UpdateContact(activity.contact));
                }
                // The updating of the activity can update the linked application too
                if (activity?.application) {
                    dispatch(UpdateApplication(activity.application));
                }
                if (automaticTasks) {
                    automaticTasks.forEach((task: Task) => dispatch(AddTask(task)));
                }
                dispatch(CloseModal());
                // Continue with an other task with linked contact
                openNewTaskModal(activity);
            }
            toast.success(t(`activity.report.${isAlreadyCompleted ? 'update' : 'add'}.success`));
        } catch (e) {
            toast.error(t(`activity.report.${isAlreadyCompleted ? 'update' : 'add'}.error`));
        }
    });

    return (
        <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
                {ActivityType.Event === activityType && (
                    <RadioImageField
                        choices={[
                            { image: Mood1, title: t('common.mood.enum.missed'), value: EventMood.Missed },
                            { image: Mood2, title: t('common.mood.enum.poor'), value: EventMood.Poor },
                            { image: Mood3, title: t('common.mood.enum.satisfying'), value: EventMood.Satisfying },
                            { image: Mood4, title: t('common.mood.enum.excellent'), value: EventMood.Excellent },
                        ]}
                        label={t('common.what-mood-after-interview')}
                        name="mood"
                        required
                    />
                )}
                <TextInput
                    label={t('common.how-the-process-was')}
                    name="comment"
                    placeholder={t('common.write-a-comment')}
                    isTextArea
                />
                <ResourcesInput name="resources" />
                <BaseModal.BottomActions>
                    <Button onClick={() => closeModal()} variant="text">
                        {t('common.cancel')}
                    </Button>
                    <Button submit>{t('common.confirm')}</Button>
                </BaseModal.BottomActions>
            </form>
        </FormProvider>
    );
};

export default ActivityReportForm;
